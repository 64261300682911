@import "variables.css";

body {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
}

.sidebar .nav>li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.card.card-upgrade .card-category {
  max-width: 530px;
  margin: 0 auto;
}

/* Nucleo Style */

.demo-iconshtml {
  font-size: 62.5%;
}

.demo-icons body {
  font-size: 1.6rem;
  font-family: sans-serif;
  color: #333333;
  background: white;
}

.demo-icons a {
  color: #608CEE;
  text-decoration: none;
}

.demo-icons header {
  text-align: center;
  padding: 100px 0 0;
}

.demo-icons header h1 {
  font-size: 2.8rem;
}

.demo-icons header p {
  font-size: 1.4rem;
  margin-top: 1em;
}

.demo-icons header a:hover {
  text-decoration: underline;
}

.demo-icons .nc-icon {
  font-size: 34px;
}

.demo-icons section h2 {
  border-bottom: 1px solid #e2e2e2;
  padding: 0 0 1em .2em;
  margin-bottom: 1em;
}

.demo-icons ul {
  padding-left: 0;
}

.demo-icons ul::after {
  clear: both;
  content: "";
  display: table;
}

.demo-icons ul li {
  width: 20%;
  float: left;
  padding: 16px 0;
  text-align: center;
  border-radius: .25em;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.demo-icons ul li:hover {
  background: #f4f4f4;
}

.demo-icons ul p,
.demo-icons ul em,
.demo-icons ul input {
  display: inline-block;
  font-size: 1rem;
  color: #999999;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.demo-icons ul p {
  padding: 20px 0 0;
  font-size: 12px;
  margin: 0;
}

.demo-icons ul p::selection,
.demo-icons ul em::selection {
  background: #608CEE;
  color: #efefef;
}

.demo-icons ul em {
  font-size: 12px;
}

.demo-icons ul em::before {
  content: '[';
}

.demo-icons ul em::after {
  content: ']';
}

.demo-icons ul input {
  text-align: center;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: none;
}
.wrapper-full-page {
  background-color: black;
}
.full-page-background{
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  opacity: .4;
}

.login-page>.container {
  padding-top: 15vh;
}

.table-responsive {
  overflow: auto;
}

.nav-link {
  cursor: pointer;
}

.main-panel {
  position: relative;
  float: left;
  width: 100%;
}

.main-panel > .content {
  padding: 0;
  margin: 0;
}

section.section-informations {
  padding-top: 30px;
  padding-bottom: 75px;
}

section.section-badges {
  padding-top: 30px;
  padding-bottom: 0px;
}

h1.sgtitle {
  font-size:  2.7em;
  font-weight: 300;
  line-height: 1.2;
}

h2.sgtitle {
  font-size:  2.4em;
  font-weight: 250;
  line-height: 1;
}

img.header-logo {
  max-height: 55px;
}

h1.price {
  font-size: 2.3rem;
  color: var(--primary-color);
  font-weight: bolder;
}

.card-pricing p {
  font-size: 0.9rem;
  font-weight: 300;
}

blockquote {
  background: #eee;
  border-radius: 5px;
}

blockquote p {
  padding: 15px;
}

blockquote p::before {
  content: '\201C';
}

blockquote p::after {
  content: '\201D';
}

section.section-contact {
  padding: 60px 0px 20px 0;
  color: white;
}

@media(max-width: 992px) {
  section.section-contact {
    margin-top: 65px;
  }

  .card-pricing {
      margin-top: 0px;
  }
}

@media(max-width: 768px) {
  section.section-contact {
    margin-top: 65px;
  }

  .card-pricing {
      margin-top: 100px;
  }
}




.card {
  height: 95%;
}

.card-price-form {
    min-height: 300px;
}

.card-result-text {
    min-height: 430px;
}

#goToRDV {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  color: white;
}

#alertPrice {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 99;
  cursor: pointer;
  font-weight: bold;
  padding-bottom: 0;
}

@media(min-width: 992px) {
  #alertPrice.navbar-shrink {
    top: 79px;
  }
}

h4.section-card-title {
  font-size: 20px;
  height: 70px;
}
div.portfolio-item {
  margin-top: 25px;
}

.text-muted {
  height: 150px;
}

@media(max-width: 767px) {
  .pt-6,
  .py-6 {
    padding-top: 6rem !important;
  }
}

section#contact .form-group textarea.form-control {
  max-height: 100%;
  height: 387px;
}

@media(max-width: 767px) {
  section#contact .form-group textarea.form-control {
    height: 200px;
  }
}

section.estimate-button {
  padding: 50px 0;
}

.loading-progress {
  margin-top: 35px;
}

.progress {
  margin-top: 25px;
}

@media (max-width: 991px){
    @media (min-width: 768px){
        .header-text {
            padding-top: 3em !important;
            margin-top: 3em !important;
        }
    }
}

@media (min-width: 768px) {
  .video-element {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.video-element > :first-child {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
.video-element > img {
    height: auto;
}
@supports (--custom:property) {
  .video-element {
    position: relative;
  }
  .video-element::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (16/9));
  }
  .video-element > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}



[style*="--aspect-ratio"] > :first-child {
    width: 100%;
}
[style*="--aspect-ratio"] > img {
    height: auto;
}
@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.grecaptcha-badge{
	visibility: collapse !important;
}

@media (max-width: 991px) {
    .header-text {

    }

    .header-text-first-line{
        font-family: Raleway, Montserrat;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 30px;
        color: black;
        padding-bottom: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .header-text-second-line{
        font-family: Raleway, Montserrat;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 20px;
        color: black;
        padding-bottom: 10px;
        margin-top: 0px;
    }
}

@media (min-width: 992px) {
    .header-text {
        visibility: hidden;
    }

    .desktop-header-text-first-line{
        font-family: Raleway, Montserrat;
        font-weight: bold;
        text-transform: uppercase;
    }

    .desktop-header-text-second-line{
        font-family: Raleway, Montserrat;
        font-weight: normal;
        text-transform: uppercase;
        color: white;
    }
}
